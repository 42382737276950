import { Slug } from "~/utilities/enums/Slug";

export interface PageConfig {
  title?: string;
}

export default Object.freeze<Record<string, PageConfig>>({
  [Slug.ABOUT_US]: {
    title: "About Us",
  },
  [Slug.COMPARE]: {
    title: "Compare",
  },
  [Slug.CONTACT_US]: {
    title: "Contact Us",
  },
  [Slug.LATEST_MORTGAGE_RATES]: {
    title: "Latest Mortgage Rates",
  },
  [Slug.LATEST_PROMOTIONS]: {
    title: "Latest Promotions",
  },
  [Slug.MORTGAGE_CALCULATOR]: {
    title: "Mortgage Calculator",
  },
  [Slug.PRIVACY_POLICY]: {
    title: "Privacy Policy",
  },
  [Slug.PROJECTS]: {
    title: "Search Projects",
  },
  [Slug.PROMOTION]: {
    title: "Promotion",
  },
  [Slug.TOP_TOP_SOON]: {
    title: "Top Top Soon",
  },
  [Slug.UPCOMING_LAUNCHES]: {
    title: "Upcoming Launches",
  },
  [Slug.VIRTUAL_TOURS]: {
    title: "Virtual Tours",
  },
});

export const emptyLayoutPage = [
  Slug.LDP_EDITOR,
  Slug.ENQUIRY_RECEIVED,
  Slug.EDITOR_PREVIEW,
];
